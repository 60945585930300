interface Props {
  className?: string
}

const Logo = ({ className }: Props) => (
  <svg
    version="1.1"
    viewBox="0 0 1000 301.4"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FF9900"
      d="M620.4,235.7c-58.1,42.8-142.3,65.7-214.9,65.7
	c-101.7,0-193.2-37.6-262.5-100.2c-5.4-4.9-0.6-11.6,6-7.8c74.7,43.5,167.2,69.6,262.6,69.6c64.4,0,135.2-13.3,200.3-41
	C621.8,217.9,630,228.5,620.4,235.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FF9900"
      d="M644.5,208c-7.4-9.5-49.1-4.5-67.8-2.3c-5.7,0.7-6.6-4.3-1.4-7.8
	c33.2-23.4,87.7-16.6,94.1-8.8c6.4,7.9-1.7,62.5-32.9,88.6c-4.8,4-9.4,1.9-7.2-3.4C636.3,256.8,652,217.6,644.5,208"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M578,32.9V10.2c0-3.4,2.6-5.7,5.7-5.7h101.7
	c3.3,0,5.9,2.4,5.9,5.7v19.5c0,3.3-2.8,7.5-7.7,14.3L631,119.2c19.6-0.5,40.3,2.4,58,12.4c4,2.3,5.1,5.6,5.4,8.8v24.2
	c0,3.3-3.7,7.2-7.5,5.2c-31.3-16.4-72.9-18.2-107.5,0.2c-3.5,1.9-7.2-1.9-7.2-5.2v-23c0-3.7,0-10,3.7-15.6l61.1-87.6h-53.1
	C580.6,38.6,578,36.3,578,32.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M206.9,174.7H176c-3-0.2-5.3-2.4-5.5-5.3V10.6
	c0-3.2,2.7-5.7,6-5.7h28.9c3,0.1,5.4,2.4,5.6,5.3v20.8h0.6c7.5-20.1,21.7-29.4,40.7-29.4c19.4,0,31.5,9.4,40.2,29.4
	c7.5-20.1,24.5-29.4,42.7-29.4c13,0,27.2,5.4,35.8,17.4c9.8,13.4,7.8,32.8,7.8,49.8l0,100.2c0,3.2-2.7,5.7-6,5.7h-30.9
	c-3.1-0.2-5.6-2.7-5.6-5.7V84.8c0-6.7,0.6-23.4-0.9-29.8c-2.3-10.7-9.2-13.7-18.2-13.7c-7.5,0-15.3,5-18.5,13
	c-3.2,8-2.9,21.4-2.9,30.4v84.2c0,3.2-2.7,5.7-6,5.7H259c-3.1-0.2-5.6-2.7-5.6-5.7l0-84.2c0-17.7,2.9-43.8-19.1-43.8
	c-22.2,0-21.4,25.4-21.4,43.8v84.2C212.9,172.1,210.2,174.7,206.9,174.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M779,1.5c45.9,0,70.8,39.4,70.8,89.6c0,48.4-27.5,86.9-70.8,86.9
	c-45.1,0-69.6-39.4-69.6-88.6C709.3,40,734.2,1.5,779,1.5 M779.2,34C756.4,34,755,65,755,84.4c0,19.4-0.3,60.9,24,60.9
	c24,0,25.1-33.4,25.1-53.8c0-13.4-0.6-29.4-4.6-42.1C796,38.3,789.1,34,779.2,34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M909.3,174.7h-30.8c-3.1-0.2-5.6-2.7-5.6-5.7l0-158.9
	c0.3-2.9,2.8-5.2,6-5.2h28.7c2.7,0.1,4.9,2,5.5,4.4v24.3h0.6c8.7-21.7,20.8-32.1,42.2-32.1c13.9,0,27.4,5,36.1,18.7
	c8.1,12.7,8.1,34.1,8.1,49.4v100c-0.3,2.8-2.9,5-6,5h-31c-2.8-0.2-5.2-2.3-5.5-5V83.4c0-17.4,2-42.8-19.4-42.8
	c-7.5,0-14.5,5-17.9,12.7c-4.4,9.7-4.9,19.4-4.9,30.1v85.5C915.3,172.1,912.6,174.7,909.3,174.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M496.9,98.8c0,12.1,0.3,22.1-5.8,32.8
	c-4.9,8.7-12.8,14.1-21.4,14.1c-11.9,0-18.8-9.1-18.8-22.4c0-26.4,23.6-31.2,46.1-31.2L496.9,98.8 M528.1,174.2c-2,1.8-5,2-7.3,0.7
	c-10.3-8.5-12.1-12.5-17.8-20.6c-17,17.3-29,22.5-51,22.5c-26.1,0-46.3-16.1-46.3-48.2c0-25.1,13.6-42.2,33-50.6
	c16.8-7.4,40.3-8.7,58.2-10.8v-4c0-7.4,0.6-16.1-3.8-22.4c-3.7-5.7-11-8.1-17.4-8.1c-11.8,0-22.3,6.1-24.9,18.6
	c-0.5,2.8-2.6,5.5-5.4,5.7l-30-3.2c-2.5-0.6-5.4-2.6-4.6-6.5C417.8,11,450.7,0,480.1,0c15.1,0,34.7,4,46.6,15.4
	c15.1,14.1,13.6,32.8,13.6,53.2v48.2c0,14.5,6,20.9,11.7,28.7c2,2.8,2.4,6.1-0.1,8.2C545.6,159.1,534.4,168.9,528.1,174.2
	L528.1,174.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M91.2,98.8c0,12.1,0.3,22.1-5.8,32.8
	c-4.9,8.7-12.7,14.1-21.4,14.1c-11.9,0-18.8-9.1-18.8-22.4c0-26.4,23.6-31.2,46-31.2V98.8 M122.4,174.2c-2,1.8-5,2-7.3,0.7
	c-10.3-8.5-12.1-12.5-17.8-20.6c-17,17.3-29,22.5-51,22.5c-26,0-46.3-16.1-46.3-48.2C0,103.5,13.6,86.4,33,78
	c16.8-7.4,40.3-8.7,58.2-10.8v-4c0-7.4,0.6-16.1-3.7-22.4c-3.8-5.7-11-8.1-17.4-8.1c-11.8,0-22.3,6.1-24.9,18.6
	c-0.5,2.8-2.6,5.5-5.4,5.7l-30-3.2c-2.5-0.6-5.3-2.6-4.6-6.5C12.1,11,45,0,74.4,0c15.1,0,34.7,4,46.6,15.4
	c15.1,14.1,13.6,32.8,13.6,53.2v48.2c0,14.5,6,20.9,11.7,28.7c2,2.8,2.4,6.1-0.1,8.2C139.9,159.1,128.7,168.9,122.4,174.2
	L122.4,174.2"
    />
  </svg>
)

export default Logo
